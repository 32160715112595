import Axios from "axios";

const CWPoleDisplay = (PoleDisplayData, type, dueAmount) => {
    let data = "";
    if (type === "Quantity update") {
        data = `
            <line>
                <left>${PoleDisplayData.name}</left>
                <right>x${PoleDisplayData.weight}</right>
            </line>
            <line>
                <left>${PoleDisplayData.realPrice}</left>
                <right>${parseInt(PoleDisplayData.realPrice * PoleDisplayData.weight)}</right>
            </line>`;
    } else if (type === "Add item to cart") {
        data = `
            <line>
                <left>${PoleDisplayData.name}</left>
                <right>x${PoleDisplayData.weight}</right>
            </line>
            <line>
                <left>${PoleDisplayData.realPrice}</left>
                <right></right>
            </line>`;
    } else if (type === "Total to pay" || type === "Show due payment" || type === "Show payment success") {
        data = `
            <line>
                <left>Total</left>
                <right>${PoleDisplayData.total}</right>
            </line>`;
        if (type === "Total to pay") {
            data += `
                <line>
                    <left>Remaining to Pay</left>
                    <right>${PoleDisplayData.total}</right>
                </line>`;
        } else if (type === "Show due payment") {
            data += `
                <line>
                    <left>Remaining to Pay</left>
                    <right>${dueAmount ? parseInt(PoleDisplayData.total - dueAmount) : ""}</right>
                </line>`;
        } else if (type === "Show payment success") {
            data += `
                <line>
                    <left>Thank you!</left>
                    <right>Visit again</right>
                </line>`;
        }
    }

    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;

    let PoleDisplayPayload = `
    <pole_display>
        ${data}
    </pole_display>`;

    Axios.post(`${printerURL}printer`, PoleDisplayPayload, {
        headers: {
            "Content-Type": "text/plain",
          }
    })
        .then(() => {
            // console.log("CWPoleDisplay success!");
        })
        .catch((response) => {
            console.log("Error in CWPoleDisplay", response);
        });
};

export default CWPoleDisplay;