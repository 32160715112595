import Axios from "axios";

const CWOpenDrawer = () => {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  
    let openDrawerPayload ={
        "cash_drawer": {
            "action": "open"
        }
    };
    Axios.post(`${printerURL}printer`, openDrawerPayload, {
      headers: {
        "Content-Type": "application/xml; charset=UTF-8",
        Accept: "application/xml",
      },
    })
      .then(() => {
        console.log("openDrawer success!");
      })
      .catch((response) => {
        console.log("openDrawer failed!", response);
      });
  };

  export default CWOpenDrawer;