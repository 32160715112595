import { all, put, takeLatest, call } from "redux-saga/effects";

const SSOURL = process.env.REACT_APP_SSOURL;
const redirectUrl = process.env.REACT_APP_redirectURL;

export function* genericFunction(action) {
  const { payload, method, successType, failureType, resolve, reject } = action;
  try {
    if (method) {
      const response = yield call(method, payload);
      yield put({
        type: successType,
        payload: response.data,
      });
      console.log("response", response);
      if (resolve) resolve(response.data);
    }
  } catch (error) {
    yield put({
      type: "HANDLE_ERRORS_REQUEST",
      successType: successType,
      successAction: action,
      error: error,
      failureType: failureType,
    });
    if (reject) reject(error);
  }
}

export function* handleError(action) {
  const { error, successAction, failureType, successType } = action;
  console.log("error", error.response.status);
  debugger;
  if (error && error.response && error.response.status) {
    if (error.response.status == 401) {
      window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
      // window.location.assign(`/#/pos`);
    }
  }
}

let requestActions = ["UPSERT_POSLOG_REQUEST", "UPSERT_TILL_REQUEST", "UPSERT_POS_ACTIVITY_REQUEST"];

export default function* root() {
  var APIs = [takeLatest("HANDLE_ERRORS_REQUEST", handleError)];

  for (var key of requestActions) {
    APIs.push(takeLatest(key, genericFunction));
  }
  yield all([...APIs]);
}
