import API from "../requests";

const upsertPOSLog = ({ tillId, userId, bUnitId, dayOpeningArray }) => {
  return {
    type: "UPSERT_POSLOG_REQUEST",
    payload: {
      query: `
              mutation {
                upsertPOSLog(order: {
                  tillId: "${tillId}",
                  userId: "${userId}", 
                  bUnitId: "${bUnitId}", 
                  lines: [${dayOpeningArray}]
                }) {
                  status   
                  message
                }
              }
            `,
    },
    method: API.upsertPOSLog,
    successType: "UPSERT_POSLOG_SUCCESS",
    failureType: "UPSERT_POSLOG_FAILURE",
  };
};

const upsertTill = ({ newStringRequest }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "UPSERT_TILL_REQUEST",
        payload: {
          query: `mutation {
            upsertTill(tillInfo: ${newStringRequest}) {    
              status
              message
              cwrTillID
              tillCash {
                cwrTillCashId
                date
                finPaymentmethodId
                opening
                sales
                notes
                netsales
                cashin
                cashout
                retainAmt
                closing
                returns
                iscash
                isclose
                storeDailyOpsTillid
                cashEvents {
                  cwrCashEventsID
                  amount
                  expected
                  diff
                  transactionCount
                  type
                  description
                  cashEventDetails {
                    cwrCashEventdetailsID
                    count
                    amount
                    denomination
                  }
                }
              }
            }
          }`,
        },
        method: API.upsertTill,
        successType: "UPSERT_TILL_SUCCESS",
        failureType: "UPSERT_TILL_FAILURE",
        resolve,
        reject, // Pass resolve and reject to the saga
      });
    });
  };
};

// const upsertPOSActivity = ({ time, csUserId, csBunitId, cwrTillRegHistoryId }) => {
//   return {
//     type: "UPSERT_POS_ACTIVITY_REQUEST",
//     payload: {
//       query: `
//         mutation {
//           upsertPOSActivity(tillActivity: [
//             {
//               csBunitId: "${csBunitId}"
//               csUserId: "${csUserId}"
//               tillRegistrationId: "${cwrTillRegHistoryId}"
//               type: "LO"
//               time: "${time}"
//             }
//           ]) {
//             status
//             message
//           }
//         }
//       `,
//     },
//     method: API.upsertPOSActivity,
//     successType: "UPSERT_POS_ACTIVITY_SUCCESS",
//     failureType: "UPSERT_POS_ACTIVITY_FAILURE",
//   };
// };

const upsertPOSActivity = ({ time, csUserId, csBunitId, cwrTillRegHistoryId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "UPSERT_POS_ACTIVITY_REQUEST",
        payload: {
          query: `
            mutation {
              upsertPOSActivity(tillActivity: [
                {
                  csBunitId: "${csBunitId}"
                  csUserId: "${csUserId}"
                  tillRegistrationId: "${cwrTillRegHistoryId}"
                  type: "LO"
                  time: "${time}"
                }
              ]) {
                status
                message
              }
            }
          `,
        },
        method: API.upsertPOSActivity,
        successType: "UPSERT_POS_ACTIVITY_SUCCESS",
        failureType: "UPSERT_POS_ACTIVITY_FAILURE",
        resolve,
        reject, // Pass resolve and reject to the saga
      });
    });
  };
};

export { upsertPOSLog, upsertTill, upsertPOSActivity };
