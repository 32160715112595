import React, { useState, useEffect } from "react";
import { Row, Col, message, Input, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clock } from "../../utility/clock";
import { tillClosingPrint } from "../../lib/printer/tillCloseReceipt";
import CancelArrow from "../../assets/images/cancelArrow.svg";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import retailStyles from "../../constants/retailPos.json";
import "../style.css";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";
import TillCloseHCPrint from "../../lib/printer/tillCloseHCPrinter";
import { upsertPOSActivity, upsertTill, upsertPOSLog } from "../../store/actions";
import TillCloseCWHCPrint from "../../lib/printer/tillCloseCWHCprinter";

const CashToKeep = () => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const dispatch = useDispatch();

  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const domainURL = process.env.REACT_APP_domain;
  const [allExpectedAmount, setAllExpectedAmount] = useState(0);
  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [allPaymentData, setAllPaymentData] = useState();
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const sessionId = tillSession.tillSessionId;
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  const [noteValue, setNoteValue] = useState("");
  const [cashToKeep, setCashToKeep] = useState(0);
  const [cashValues, setCashValues] = useState({
    cashIn: 0,
    cashOut: 0,
    pettCashIn: 0,
    pettCashOut: 0,
  });
  const authHeaders = getOAuthHeaders();
  const ObFlag = tillData.tillAccess.cwrTill.tillCloseTemplate.obController === "Y" ? true : false;
  const CWHardwareController = tillData.tillAccess.cwrTill.printTemplate.cwController === "Y" ? true : false;

  useEffect(async () => {
    let obj = cashValues;
    let payments = [];
    await db.tillEvents
      .where("tillSessionId")
      .equals(sessionId)
      .toArray()
      .then((tillEvent) => {
        if (tillEvent.length > 0 && tillEvent[0].cashInOutData) {
          setCashValues(tillEvent[0].cashInOutData);
          obj = tillEvent[0].cashInOutData;
        }
        if (tillEvent.length > 0) {
          tillEvent[0].allPaymentsData.map((payment) => {
            if (payment.name.toLowerCase() !== "cash") {
              payment.amount = payment.amount > 0 ? payment.amount : payment.expectedAmount;
              payment.actualAmount = payment.expectedAmount;
              payment.difference = 0;
            }
          });
          payments = tillEvent[0].allPaymentsData;
        }
      });

    if (posConfig.showDenominations === "N") {
      db.orders
        .where("tillSessionId")
        .equals(sessionId)
        .toArray()
        .then((orders) => {
          const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
          setTotalOpeningAmount(openAmount);
          const updatedTillSession = tillSession;
          const updatedAllPaymentsData = payments;
          const aPi = updatedAllPaymentsData?.findIndex((apd) => apd.name.toLowerCase() === "cash");
          updatedAllPaymentsData[aPi].amount = totalAmount;
          updatedAllPaymentsData[aPi].expectedAmount = parseFloat((updatedAllPaymentsData[aPi].expectedAmount - updatedAllPaymentsData[aPi].paymentReturn).toFixed(2));
          updatedAllPaymentsData[aPi].cashSaleAmount = cashSaleAmount;
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].amount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].difference.toFixed(2));
          setCashSaleAmount(updatedAllPaymentsData[aPi].expectedAmount);
          updatedTillSession.closingTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          updatedTillSession.closingCash = totalAmount;
          updatedTillSession.closingCashDenominations = [];
          updatedTillSession.allPaymentsData = updatedAllPaymentsData;
          updatedTillSession.totalOrdersCount = orders.length;
          db.tillEvents.where("tillSessionId").equals(updatedTillSession.tillSessionId).modify(updatedTillSession);
          localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
          setAllExpectedAmount(updatedAllPaymentsData[aPi].expectedAmount);
          setAllTransactionCount(updatedAllPaymentsData[aPi].transactionCount);
          setTotalAmount(updatedAllPaymentsData[aPi].amount);
          setPaymentsList([...payments]);
        });
    } else if (posConfig.showDenominations === "Y") {
      const tillSessionPayments = tillSession.allPaymentsData;
      let totalAmountAllPayments = 0;
      let transactionCount = 0;
      let total = 0;
      let salesAmount = 0;
      tillSessionPayments?.map((pay) => {
        totalAmountAllPayments += pay.expectedAmount;
        transactionCount += pay.transactionCount;
        if (pay.name.toLowerCase() === "cash") {
          salesAmount += pay?.cashSaleAmount;
          pay.cashSaleAmount = pay?.expectedAmount;
        } else {
          salesAmount += pay?.expectedAmount;
        }
        pay.expectedAmount = pay.expectedAmount - pay.paymentReturn;
        total += parseFloat(pay.actualAmount) >= 0 ? parseFloat(pay.actualAmount) : 0;
        return null;
      });
      const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
      setSalesAmount(salesAmount);
      payments.map((ele) => {
        ele.expectedAmount = parseFloat(parseFloat(ele.expectedAmount).toFixed(2));
      });
      totalAmountAllPayments = salesAmount + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut + openAmount;
      setAllPaymentData(tillSessionPayments);
      setAllExpectedAmount(totalAmountAllPayments);
      setAllTransactionCount(transactionCount);
      setTotalAmount(total);
      setPaymentsList([...payments]);
    }
  }, []);

  const history = useHistory();
  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  const [checkedValue, setCheckedValue] = useState("0");

  useEffect(async () => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const onChange = (e) => {
    if (e.target.value === "0") {
      setCashToKeep(JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount);
    } else setCashToKeep(0);
    setCheckedValue(e.target.value);
  };

  const excuteCloseTillAll = async () => {
    tillSession.allPaymentsData = allPaymentData;
    localStorage.setItem("tillSession", JSON.stringify(tillSession));
    await db.tillEvents
      .where("tillSessionId")
      .equals(tillSession.tillSessionId)
      .modify((tillEvent) => {
        tillEvent.allPaymentsData = allPaymentData;
      });
    const updatedPaymentsList = paymentsList;
    let closingSession = tillSession;
    await db.tillEvents
      .where("tillStatus")
      .equalsIgnoreCase("open")
      .toArray()
      .then((tillEvent) => {
        if (tillEvent.length > 0) {
          closingSession = tillEvent[0];
          closingSession.checkedValue = checkedValue === "0" ? JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount : cashToKeep;
        }
      });
    closingSession.totalClosingExpectedAmount = parseFloat(allExpectedAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    closingSession.totalClosingTransactions = parseFloat(allTransactionCount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    closingSession.totalClosingAmount = parseFloat(totalAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    closingSession.allPaymentsData = updatedPaymentsList;
    closingSession.isSynced = 0;
    closingSession.tillStatus = "close";
    if (authHeaders && authHeaders.access_token) {
      db.tillEvents
        .where("tillSessionId")
        .equals(closingSession.tillSessionId)
        .modify(closingSession)
        .then(async () => {
          if (ObFlag) {
            await TillCloseHCPrint(closingSession, cashValues, paymentsList, cashToKeep);
          } else if (CWHardwareController) {
            await TillCloseCWHCPrint(closingSession, cashValues, paymentsList, cashToKeep);
          } else {
            tillClosingPrint(closingSession);
          }
        });

      await db.tillEvents
        .where("isSynced")
        .equals(0)
        .toArray()
        .then(async (events) => {
          if (events.length > 0) {
            for (let i = 0; i < events.length; i += 1) {
              const event = events[i];
              const tillCash = [];
              let tillRegistration = await db.tillRegistrations.toArray();
              let returnCash;
              for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                const paymentObj = event.allPaymentsData[j];
                if (paymentObj.name.toLowerCase() === "cash") {
                  const openedCashEvents = [];
                  const closedCashEvents = [];
                  returnCash = paymentObj.paymentReturn;
                  for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                    openedCashEvents.push({
                      cwrCashEventdetailsID: uuidv4().replace(/-/g, "").toUpperCase(),
                      count: event.openingCashDenominations[x].count,
                      amount: parseFloat(event.openingCashDenominations[x].amount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                      denomination: event.openingCashDenominations[x].value,
                    });
                  }
                  for (let z = 0; z < event.closingCashDenominations?.length; z += 1) {
                    closedCashEvents.push({
                      cwrCashEventdetailsID: uuidv4().replace(/-/g, "").toUpperCase(),
                      count: event.closingCashDenominations[z].count,
                      amount: parseFloat(event.closingCashDenominations[z].amount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                      denomination: event.closingCashDenominations[z].value,
                    });
                  }
                  let cashInOut = [];
                  let cashIn = 0;
                  let cashOut = 0;
                  let pattyCashIn = 0;
                  let pettyCashOut = 0;
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ notes: noteValue });
                  await db.cashInCashOut.toArray((data) => {
                    data.map((item) => {
                      if (item.type === "cashIn") {
                        cashIn += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "CI",
                          time: moment(item.date).format("YYYY-MM-DD HH:mm:ss"),
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note || "",
                        });
                      } else if (item.type === "cashOut") {
                        cashOut += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "CO",
                          time: moment(item.date).format("YYYY-MM-DD HH:mm:ss"),
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note || "",
                        });
                      }
                      if (item.type === "pettyCashIn") {
                        pattyCashIn += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "PCI",
                          time: moment(item.date).format("YYYY-MM-DD HH:mm:ss"),
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note || "",
                        });
                      }
                      if (item.type === "pettyCashOut") {
                        pettyCashOut += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "PCO",
                          time: moment(item.date).format("YYYY-MM-DD HH:mm:ss"),
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note || "",
                        });
                      }
                    });
                  });

                  let ordersData = [];
                  let matchedTillSession = {};
                  await db.ordersData.toArray((data) => {
                    data.forEach((item) => {
                      if (item.tillSessionId === event.tillSessionId) {
                        matchedTillSession = item;
                        item.salesTax.forEach((tax) => {
                          ordersData.push({
                            csTaxId: tax.csTaxID,
                            amount: tax.taxableAmt,
                          });
                        });
                      }
                    });
                  });
                  let netSalesAmount = paymentObj.cashSaleAmount > 0 ? parseFloat(matchedTillSession.netTotal * (paymentObj.cashSaleAmount / matchedTillSession.grossTotal)) : 0;
                  let returnTaxAmount =
                    Math.abs(matchedTillSession.totaReturnlTax) > 0 && Math.abs(paymentObj.paymentReturn) > 0 && Math.abs(matchedTillSession.grossReturnTotal) > 0
                      ? matchedTillSession.netReturnTotal * (paymentObj.paymentReturn / matchedTillSession.grossReturnTotal)
                      : 0;
                  let tillCashData = JSON.parse(localStorage.getItem("tillCash")).filter((ele) => ele.finPaymentmethodId === paymentObj.finPaymentmethodId);
                  tillCash.push({
                    cwrTillCashId: tillCashData.length > 0 ? tillCashData[0].cwrTillCashId : uuidv4().replace(/-/g, "").toUpperCase(),
                    date: moment(event.openingTime).format("YYYY-MM-DD"),
                    finPaymentmethodId: paymentObj.finPaymentmethodId,
                    finFinancialAccount2Id: paymentObj.finDayCloseAccountId ? paymentObj.finDayCloseAccountId : null,
                    opening: event.openingCash,
                    opentime: moment(event.openingTime).format("YYYY-MM-DD HH:mm:ss"),
                    closetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    sales: parseFloat(paymentObj.cashSaleAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    netsales: netSalesAmount > 0 ? parseFloat(netSalesAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0,
                    cashin: cashIn,
                    cashout: cashOut,
                    retainAmt: checkedValue === "0" ? JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount : cashToKeep,
                    closing: parseFloat(event.closingCash?.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    returns: parseFloat(paymentObj.paymentReturn.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    netReturns: parseFloat(returnTaxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    iscash: 1,
                    isclose: 1,
                    notes: noteValue,
                    pettyCashIn: pattyCashIn,
                    pettyCashOut: pettyCashOut,
                    cashInOut: cashInOut,
                    cashEvents: [
                      {
                        cwrCashEventsID: uuidv4().replace(/-/g, "").toUpperCase(),
                        amount: parseFloat(event.openingCash.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                        expected: 0,
                        diff: 0,
                        transactionCount: 0,
                        type: "Open",
                        description: "",
                        cashEventDetails: openedCashEvents,
                      },
                      {
                        cwrCashEventsID: uuidv4().replace(/-/g, "").toUpperCase(),
                        amount: parseFloat(event.closingCash.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                        expected: parseFloat(paymentObj.expectedAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                        diff: parseFloat(paymentObj.difference.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                        transactionCount: paymentObj.transactionCount,
                        type: "Close",
                        description: "",
                        cashToKeep: checkedValue === "0" ? JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount : cashToKeep,
                        cashEventDetails: closedCashEvents,
                      },
                    ],
                  });
                } else {
                  let cashInOut = [];
                  let cashIn = 0;
                  let cashOut = 0;
                  let pattyCashIn = 0;
                  let pettyCashOut = 0;
                  let ordersData = [];
                  let matchedTillSession = {};
                  await db.ordersData.toArray((data) => {
                    data.map((item) => {
                      if (item.tillSessionId === event.tillSessionId) {
                        matchedTillSession = item;
                        item.salesTax.map((tax) => {
                          ordersData.push({
                            csTaxId: tax.csTaxID,
                            amount: tax.taxableAmt,
                          });
                        });
                      }
                    });
                  });
                  let netSalesAmount = paymentObj.expectedAmount > 0 ? parseFloat(matchedTillSession.netTotal * (paymentObj.expectedAmount / matchedTillSession.grossTotal)) : 0;
                  let returnTaxAmount =
                    Math.abs(matchedTillSession.totaReturnlTax) > 0 && Math.abs(paymentObj.paymentReturn) > 0 && Math.abs(matchedTillSession.grossReturnTotal) > 0
                      ? matchedTillSession.netReturnTotal * (paymentObj.paymentReturn / matchedTillSession.grossReturnTotal)
                      : 0;
                  await db.cashInCashOut.toArray((data) => {
                    data.map((item) => {
                      if (item.type === "cashIn") {
                        cashIn += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "CI",
                          time: item.date,
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note === undefined || item.note === null ? "" : item.note,
                        });
                      } else if (item.type === "cashOut") {
                        cashOut += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "CO",
                          time: item.date,
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note === undefined || item.note === null ? "" : item.note,
                        });
                      }
                      if (item.type === "pettyCashIn") {
                        pattyCashIn += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "PCI",
                          time: item.date,
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note === undefined || item.note === null ? "" : item.note,
                        });
                      }
                      if (item.type === "pettyCashOut") {
                        pettyCashOut += parseFloat(item.amount);
                        cashInOut.push({
                          cwrTillCashInOutId: item.key,
                          amount: parseFloat(item.amount),
                          type: "PCO",
                          time: item.date,
                          updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                          description: item.note === undefined || item.note === null ? "" : item.note,
                        });
                      }
                    });
                  });
                  await db.cashInCashOut.clear();
                  tillCash.push({
                    cwrTillCashId: uuidv4().replace(/-/g, "").toUpperCase(),
                    date: moment(event.openingTime).format("YYYY-MM-DD"),
                    finPaymentmethodId: paymentObj.finPaymentmethodId,
                    finFinancialAccount2Id: paymentObj.finDayCloseAccountId ? paymentObj.finDayCloseAccountId : null,
                    opening: 0,
                    opentime: moment(event.openingTime).format("YYYY-MM-DD HH:mm:ss"),
                    closetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    sales: parseFloat(paymentObj.expectedAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    netsales: netSalesAmount > 0 ? parseFloat(netSalesAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0,
                    cashin: cashIn,
                    cashout: cashOut,
                    retainAmt: 0,
                    notes: noteValue,
                    closing: parseFloat((paymentObj.amount - paymentObj.paymentReturn).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    returns: parseFloat(paymentObj.paymentReturn.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    netReturns: parseFloat(returnTaxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                    iscash: 0,
                    isclose: 1,
                    pettyCashIn: pattyCashIn,
                    pettyCashOut: pettyCashOut,
                    cashInOut: cashInOut,
                    cashEvents: [
                      {
                        cwrCashEventsID: uuidv4().replace(/-/g, "").toUpperCase(),
                        amount: parseFloat(paymentObj.amount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                        expected: parseFloat(paymentObj.expectedAmount).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision),
                        diff: parseFloat(paymentObj.difference.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                        transactionCount: paymentObj.transactionCount,
                        type: "Close",
                        description: "",
                        cashEventDetails: [],
                      },
                    ],
                  });
                }
              }
              let taxInfo = [];
              await db.ordersData.toArray((data) => {
                data.map((item) => {
                  if (item.tillSessionId === event.tillSessionId) {
                    item.salesTax.map((tax) => {
                      taxInfo.push({
                        csTaxId: tax.csTaxID,
                        amount: parseFloat(tax.taxableAmt.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
                      });
                    });
                  }
                });
              });

              let taxInfoData = {
                cwrTillID: tillData.tillAccess.cwrTill.cwrTillID,
                csbunitID: tillData.tillAccess.csBunit.csBunitId,
                csclientID: tillData.tillAccess.csClientId,
                created: event.openingTime,
                createdby: tillData.tillAccess.csUserId,
                updated: event.openingTime,
                updatedby: tillData.tillAccess.csUserId,
                storeDailyOpsTillid: localStorage.getItem("storeDailyOpsTillid"),
                taxInfo: taxInfo,
                tillCash: tillCash,
              };
              const stringifiedData = JSON.stringify(taxInfoData);
              const newStringifiedFields = stringifiedData.replace(/\\"/g, '\\"');
              const newStringRequest = JSON.stringify(newStringifiedFields);

              db.ordersData
                .where("tillSessionId")
                .equals(event.tillSessionId)
                .modify((event) => {
                  event.tillStatus = "closed";
                });
              db.paymentsData
                .where("tillSessionId")
                .equals(event.tillSessionId)
                .modify((event) => {
                  event.tillStatus = "closed";
                });
              let userDetails = localStorage.getItem("userData");
              db.tillEvents
                .where("tillSessionId")
                .equals(event.tillSessionId)
                .modify(async (event) => {
                  event.tillStatus = "closed";
                  event.isSynced = 1;
                  event.tillRegistration = tillRegistration;
                  event.tillInfo = newStringRequest;
                  event.user = JSON.parse(localStorage.getItem("userData")).user;
                });

              const syncTillSession = async () => {
                try {
                  const responseData = await dispatch(upsertTill({ newStringRequest }));

                  console.log("Data Synced Successfully", responseData.data);
                  const status = responseData.data?.upsertTill?.status;

                  if (status !== "200") return;

                  localStorage.removeItem("storeDailyOpsTillid");
                  const time = moment().format("YYYY-MM-DD HH:mm:ss");
                  const csUserId = tillRegistration[0]?.tillAccess[0]?.csUser?.csUserId;
                  const csBunitId = tillRegistration[0]?.tillAccess[0]?.csBunit?.csBunitId;
                  const cwrTillRegHistoryId = tillRegistration[0]?.tillHistory[0]?.cwrTillRegHistoryId;
                  const posActivityResponse = await dispatch(upsertPOSActivity({ time, csUserId, csBunitId, cwrTillRegHistoryId }));
                  const posActivityStatus = posActivityResponse.data?.upsertPOSActivity?.status;
                  if (posActivityStatus !== "200") return;

                  if (posConfig.closingRegister === "Y") {
                    if (localStorage.getItem("unlink")) {
                      unlinkTillAPI();
                    } else {
                      clearSessionAndRedirect();
                    }
                  }
                } catch (error) {
                  console.log("Failed Till Session Sync ====> ", error);
                  await db.tillEvents
                    .where("tillSessionId")
                    .equals(event.tillSessionId)
                    .modify((event) => {
                      event.isSynced = 0;
                      event.tillStatus = "close";
                    });

                  clearSessionAndRedirect(error);
                }
              };

              const clearSessionAndRedirect = (error) => {
                document.cookie.split(";").forEach((cookie) => {
                  const name = cookie.split("=")[0].trim();
                  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict; domain=${domainURL}`;
                });
                if (error?.response?.status !== 401 || !error) {
                  localStorage.removeItem("reduxState");
                  localStorage.removeItem("tillSession");
                  window.location.assign("/");
                }
              };

              syncTillSession();
            }
          }
        });
      if (JSON.parse(localStorage.getItem("posConfig"))?.DCL === "Y") {
        const timeMark = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const dayClosingArray = [];
        const trxId = uuidv4().replace(/-/g, "").toUpperCase();
        dayClosingArray.push(`{
            type: "DCL",
            action: "LOG",
            description: "${timeMark}",
            date: "${currentDate}",
            time: "${timeMark}",
            orderNo: "",
            remarks: "",
            transactionId: "${trxId}"
            status: "SCS"
            duration: null
          }`);
        try {
          const tillId = tillData.tillAccess.cwrTill.cwrTillID || "";
          const userId = tillData?.tillAccess?.csUserId || "";
          const bUnitId = tillData?.tillAccess?.csBunit?.csBunitId || "";

          if (!tillId || !userId || !bUnitId || !dayClosingArray) {
            console.error("Missing required fields for upsertPOSLog mutation");
            return;
          }
          dispatch(upsertPOSLog({ tillId, userId, bUnitId, dayClosingArray }));
        } catch (error) {
          console.error("Error during upsertPOSLog mutation:", error);
        }
      }
    }
  };

  const unlinkTillAPI = async () => {
    try {
      let id = tillData.tillAccess.cwrTill.cwrTillID;

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            unlinkTill(tillId: "${id}") {
              status
              message
            } 
          }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      if (response.data.data.unlinkTill.status === "200") {
        const keepKey = "";
        localStorage.clear();
        await Promise.all(
          db.tables.map(async (table) => {
            if (table.name !== keepKey) {
              await table.clear();
            }
          })
        );
      }

      localStorage.removeItem("unlink");
      window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
    } catch (error) {
      console.error("Error in unlinkTillAPI:", error);
    }
  };

  const handleAddAmount = (value) => {
    if (checkedValue === "0" && posConfig?.manualCashToKeep === "Y") {
      if (value === "clear") {
        setCashToKeep(0);
      } else if (value === "x") {
        setCashToKeep(`${cashToKeep.toString().substring(0, cashToKeep.toString().length - 1)}`);
      } else {
        setCashToKeep(`${cashToKeep}${value}`);
      }
    }
  };

  return (
    <div
      style={{
        ...data.openTill.mainContainer,
        backgroundColor: "#F3F4F9",
        fontSize: "1vw",
      }}
    >
      <div>
        <Row style={{ height: "6vh", padding: "1% 0 0 1%" }}>
          <Col span={11}>
            <label
              style={{
                fontSize: "1.8em",
                color: "#0F0718",
                letterSpacing: "0.36px",
                opacity: 1,
                fontWeight: "500",
                textAlign: "center",
                paddingLeft: "2vw",
              }}
            >
              Close POS Till
            </label>
          </Col>
          <Col
            span={13}
            style={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
              padding: "0 1vw",
            }}
          >
            <span style={{ fontWeight: "500", fontSize: "1em", color: "#0F0718" }}>{currentDate}</span>
            <span style={{ fontWeight: "500", fontSize: "1em", color: "#0F0718" }}>{displayClock}</span>
          </Col>
        </Row>

        <div style={{ ...data.openTill.mainCardContainer }}>
          <div
            style={{
              padding: "1vw",
              display: "flex",
              paddingBottom: "0",
              fontSize: "1vw",
            }}
          >
            <div
              style={{
                borderRadius: "5px",
                width: "75%",
                backgroundColor: "#fff",
                height: "84vh",
              }}
            >
              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  backgroundColor: "rgba(146, 144, 152, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "1.25em",
                    fontWeight: 500,
                    paddingLeft: "3vw",
                    verticalAlign: "middle",
                    color: "#0F0718",
                  }}
                >
                  Select Cash To Keep
                </span>
              </div>
              <div style={{ padding: "2vh 3vw" }}>
                {/* <Checkbox onChange={onChange} checked={checkedValue === "Nothing"} value="Nothing" style={{ borderColor: "rgba(146, 144, 152, 0.5)" }}>
                  <span style={{ fontWeight: "500", fontSize: "2.5vh" }}>Nothing</span>
                </Checkbox> */}
                <div style={{ marginBottom: 16 }} />
                <Checkbox value="0" onChange={onChange} checked={checkedValue === "0"} style={{ fontSize: "1vw", transform: "scale(1.3)" }}>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      color: "#0F0718",
                    }}
                  >
                    {JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount}
                  </span>
                </Checkbox>
              </div>
            </div>
            <div
              style={{
                width: "25%",
                paddingLeft: "2.3%",
                fontSize: "1vw",
                position: "relative",
              }}
            >
              <div style={{ ...data.openTill.opentillRightCard }}>
                {/* <Form.Item style={{ marginBottom: "10px" }} name="amount"> */}
                <Input
                  disabled={posConfig?.manualCashToKeep === "Y" && checkedValue !== "Nothing" ? false : true}
                  id="issueCardAmount"
                  placeholder="Enter Amount"
                  // prefix={<UserOutlined style={{color:"#929098"}}/>}
                  size="large"
                  allowClear={false}
                  value={cashToKeep}
                  style={{
                    ...retailStyles.posScreenStyles.orderHistoryModal.closeTillSearchInput,
                    padding: "1vh 1.5vw",
                    height: "9vh",
                    marginBottom: "10px",
                  }}
                />
                {/* </Form.Item> */}
              </div>
              <Row style={{ height: "", fontSize: "1vw", paddingTop: "0.4vh" }} gutter={[8, 6]}>
                <Col span={8}>
                  <button
                    id="sm-amount-button1"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("1");
                      e.preventDefault();
                    }}
                  >
                    1
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button2"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("2");
                      e.preventDefault();
                    }}
                  >
                    2
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button3"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("3");
                      e.preventDefault();
                    }}
                  >
                    3
                  </button>
                </Col>
                {/* <Col span={8}>
                          <button className="amt-dial-btn-cashtoKeep" id="sm-amount-buttonRemove" onClick={(e) => {handleAddAmount("x");e.preventDefault()}}>
                            <img src={CancelArrow} alt="back space" width={25} height={15} />
                          </button>
                        </Col> */}
                <Col span={8}>
                  <button
                    id="sm-amount-button4"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("4");
                      e.preventDefault();
                    }}
                  >
                    4
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button5"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("5");
                      e.preventDefault();
                    }}
                  >
                    5
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button6"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("6");
                      e.preventDefault();
                    }}
                  >
                    6
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button7"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("7");
                      e.preventDefault();
                    }}
                  >
                    7
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button8"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("8");
                      e.preventDefault();
                    }}
                  >
                    8
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button0"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("9");
                      e.preventDefault();
                    }}
                  >
                    9
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button0"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("0");
                      e.preventDefault();
                    }}
                  >
                    0
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button00"
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount(".");
                      e.preventDefault();
                    }}
                  >
                    .
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    id="sm-amount-button."
                    className="amt-dial-btn-cashtoKeep"
                    onClick={(e) => {
                      handleAddAmount("x");
                      e.preventDefault();
                    }}
                  >
                    <img src={CancelArrow} alt="back space" width={20} height={15} />
                  </button>
                </Col>
                <Col span={8}>
                  <button
                    onClick={(e) => {
                      handleAddAmount("clear");
                    }}
                    id="sm-amount-addQuantity"
                    className="amt-dial-btn-cashtoKeep"
                  >
                    Clear
                  </button>
                </Col>
                <Col span={16}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    id="sm-amount-addQuantity"
                    className="amt-dial-btn-cashtoKeep"
                  >
                    Enter
                  </button>
                </Col>
                <Col span={24} style={{ position: "absolute", bottom: 0, width: "94%" }}>
                  <button
                    onClick={(e) => {
                      excuteCloseTillAll();
                    }}
                    id="sm-amount-addQuantity"
                    className="amt-dial-btn-cashtoKeep-submit"
                  >
                    Submit
                  </button>
                </Col>
                <Col span={24} style={{ paddingTop: "1vh" }}>
                  <button
                    onClick={(e) => {
                      history.push("close-till-all");
                    }}
                    id="sm-amount-addQuantity"
                    className="amt-dial-btn-cashtoKeep-back"
                  >
                    Back
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashToKeep;
