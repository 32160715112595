import GiftCardCWHCPrint from "./giftCardCWHCPrinter";
import Axios from "axios";
import CWOpenDrawer from "./CWOpenDrawer";
import CWPoleDisplay from "./CWPoleDisplay";


const CWHCPrint = (printData,PStatus) => {
  let receiptData = { ...printData };
  if (!PStatus && !receiptData.draftFlag && !receiptData.layAway) {
    CWPoleDisplay(receiptData.items, "Show payment success");
  }
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const sales_receipt_copies = posConfig?.sales_receipt_copies && posConfig?.sales_receipt_copies !== null ? posConfig?.sales_receipt_copies : 0;
  const return_receipt_copies = posConfig?.return_receipt_copies && posConfig?.return_receipt_copies !== null ? posConfig?.return_receipt_copies : 0;
  const layaway_receipt_copies = posConfig?.layaway_receipt_copies && posConfig?.layaway_receipt_copies !== null ? posConfig?.layaway_receipt_copies : 0;
  const layaway_paid_receipt_copies = posConfig?.layaway_paid_receipt_copies && posConfig?.layaway_paid_receipt_copies !== null ? posConfig?.layaway_paid_receipt_copies : 0;
  const loyalty_receipt_copies = posConfig?.loyalty_receipt_copies && posConfig?.loyalty_receipt_copies !== null ? posConfig?.loyalty_receipt_copies : 0;
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let isloyalty =
  (receiptData.payments && receiptData.payments.some((payment) => payment.isloyalty)) ||
  (receiptData?.paymentData && receiptData?.paymentData.some((payment) => payment?.isloyalty));
  let receiptTemplate = tillData.tillAccess.cwrTill.printTemplate.xmlcode2 ? JSON.parse(tillData.tillAccess.cwrTill.printTemplate.xmlcode2) : {};

  const groupedItems = {};
  (receiptData.items || []).forEach((item) => {
    const key = item.tax && item.tax.rate !== undefined && item.tax.rate !== null 
      ? item.tax.rate 
      : item.taxRate || "0"; // Fallback to "0" if no tax rate
    if (!groupedItems[key]) {
      groupedItems[key] = [];
    }
    groupedItems[key].push(item);
  });

  const groupedItemsByTaxRate = [];
  for (const taxRate in groupedItems) {
    const totalTaxAmount = groupedItems[taxRate].reduce(
      (total, item) =>
        total +
        (item.tax && item.tax.rate !== undefined && item.tax.rate !== null 
          ? parseFloat(parseFloat(item.linetax || 0).toFixed(2)) 
          : parseFloat(parseFloat(item.taxAmount || 0).toFixed(2))),
      0
    );
    const totalNetTotal = groupedItems[taxRate].reduce(
      (total, item) =>
        total +
        (item.tax && item.tax.rate !== undefined && item.tax.rate !== null 
          ? parseFloat(parseFloat(item.linegross || 0).toFixed(2)) 
          : parseFloat(parseFloat(item.nettotal || 0).toFixed(2))),
      0
    );
    groupedItemsByTaxRate.push({
      taxRate: taxRate,
      totalLinenet: (totalNetTotal - totalTaxAmount).toFixed(2),
      totalLinetax: totalTaxAmount.toFixed(2),
      totalLinegross: totalNetTotal.toFixed(2)
    });
  }

  // Merge groupedItemsByTaxRate and PStatus into receiptData
  const updatedReceiptData = {
    ...receiptData,
    groupedItemsByTaxRate,
    PStatus,
    previousCashBalance:receiptData.customer.retlLoyaltyBalance + receiptData.redemptionPoints
  };

  const processedReceipt = processReceiptTemplate(receiptTemplate, updatedReceiptData);
  // console.log("Final Receipt JSON:", JSON.stringify(processedReceipt, null, 2));
  let printReciptData = receiptData;
  let decodedData = JSON.stringify(processedReceipt, null, 2);
  if (printReciptData.items.length > 0 && !printReciptData.draftFlag && printReciptData.isReturn && printReciptData.layAway === "N" && return_receipt_copies !== 0 && !PStatus && !isloyalty) {
    let successfulPrints = 0;
    for (let i = 0; i < return_receipt_copies; i++) {
      Axios.post(`${printerURL}printer`, decodedData, {
        headers: {
          "Content-Type": "text/plain",
        }
      })
        .then(() => {
          console.log("Print success !");
          successfulPrints++;
          if (successfulPrints === return_receipt_copies) {
            if (printReciptData.layAway === "N" && !PStatus && !printReciptData.draftFlag && printReciptData?.payments?.some((payment) => payment?.name === "Cash")) {
              CWOpenDrawer();
            }
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("XML print failed !", response);
        });
    }
  } else if (
    printReciptData.items.length > 0 &&
    !printReciptData.draftFlag &&
    !printReciptData.isReturn &&
    printReciptData.layAway === "N" &&
    sales_receipt_copies !== 0 &&
    !PStatus && 
    !isloyalty
  ) {
    let successfulPrints = 0;
    for (let i = 0; i < sales_receipt_copies; i++) {
      Axios.post(`${printerURL}printer`, decodedData, {
        headers: {
          "Content-Type": "text/plain",
        }
      })
        .then(() => {
          console.log("Print success !");
          successfulPrints++;
          if (successfulPrints === sales_receipt_copies) {
            if (printReciptData.layAway === "N" && !PStatus && !printReciptData.draftFlag && printReciptData?.payments?.some((payment) => payment?.name === "Cash")) {
              CWOpenDrawer();
            }
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("Print failed !", response);
        });
    }
  } else if (
    printReciptData.items.length > 0 &&
    !printReciptData.draftFlag &&
    printReciptData.layAway === "Y" &&
    printReciptData.paid === 0 &&
    layaway_receipt_copies !== 0 &&
    !PStatus
  ) {
    let successfulPrints = 0;
    for (let i = 0; i < layaway_receipt_copies; i++) {
      Axios.post(`${printerURL}printer`, decodedData, {
        headers: {
          "Content-Type": "text/plain",
        }
      })
        .then(() => {
          console.log("Print success !");
          successfulPrints++;
          if (successfulPrints === layaway_receipt_copies) {
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("Print failed !", response);
        });
    }
  } else if (
    printReciptData.items.length > 0 &&
    !printReciptData.draftFlag &&
    printReciptData.layAway === "Y" &&
    printReciptData.paid !== 0 &&
    layaway_paid_receipt_copies !== 0 &&
    !PStatus
  ) {
    let successfulPrints = 0;
    for (let i = 0; i < layaway_paid_receipt_copies; i++) {
      Axios.post(`${printerURL}printer`, decodedData, {
        headers: {
          "Content-Type": "text/plain",
        }
      })
        .then(() => {
          console.log("Print success !");
          successfulPrints++;
          if (successfulPrints === layaway_paid_receipt_copies) {
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((response) => {
          console.log("Print failed !", response);
        });
    }
  }
  else if (printReciptData.items.length > 0 && !printReciptData.draftFlag && !PStatus && printReciptData.layAway === "N" && loyalty_receipt_copies !== 0 && isloyalty) {
    let successfulPrints = 0;

    // Loop for printing loyalty receipt copies
    for (let i = 0; i < loyalty_receipt_copies; i++) {
      Axios.post(`${printerURL}printer`, decodedData, {
        headers: {
          "Content-Type": "text/plain",
        }
      })
        .then(() => {
          console.log("Print success!");
          successfulPrints++;

          // Execute logic after all prints are successful
          if (successfulPrints === loyalty_receipt_copies) {
            if (printReciptData.layAway === "N" && !PStatus && !printReciptData.draftFlag && printReciptData?.payments?.some((payment) => payment?.name === "Cash")) {
              CWOpenDrawer();
            }
            printReciptData.items.forEach((data) => {
              if (printReciptData.isReturn && data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Credit Note");
              } else if (data?.giftCardType === "DIG") {
                GiftCardCWHCPrint(data, "Gift Card");
              }
            });
          }
        })
        .catch((error) => {
          console.error("Print failed!", error);
        });
    }
  } 
  else {
    Axios.post(`${printerURL}printer`, decodedData, {
      headers: {
        "Content-Type": "text/plain",
      }
    })
      .then(() => {
        console.log("Print success !");
        if (
          (printReciptData.layAway === "N" || (printReciptData.layAway === "Y" && printReciptData.paid !== 0)) &&
          !PStatus &&
          !printReciptData.draftFlag &&
          printReciptData?.payments?.some((payment) => payment?.name === "Cash")
        ) {
          CWOpenDrawer();
        }
        printReciptData.items.forEach((data) => {
          if (printReciptData.isReturn && data?.giftCardType === "DIG") {
            GiftCardCWHCPrint(data, "Credit Note");
          } else if (data?.giftCardType === "DIG") {
            GiftCardCWHCPrint(data, "Gift Card");
          }
        });
      })
      .catch((response) => {
        console.log("Print failed !", response);
      });
  }
  return processedReceipt;
};

const processReceiptTemplate = (template, data) => {
  const processedTemplate = JSON.parse(JSON.stringify(template));

  processedTemplate.elements = processedTemplate.elements.flatMap((element) => {
    // Process text elements
    if (element.type === "text" && element.content && typeof element.content === "string") {
      element.content = replacePlaceholders(element.content, data);
    }

    // Process table elements
    if (element.type === "table" && element.data && typeof element.data === "string" && element.dataMapping) {
      const dataPath = element.data.replace(/{{|}}/g, ""); // e.g., "items"
      const tableData = getDeepValue(data, dataPath) || [];
    
      const columnKeys = element.columns.map((col, idx) => ({
        key: Object.keys(element.dataMapping).find((k) => element.dataMapping[k] === Object.values(element.dataMapping)[idx]),
        width: col.width,
        alignment: col.alignment,
        format: col.format
      }));
    
      element.data = tableData.flatMap((item) => {
        // Main row
        const mainRow = {};
        for (const targetKey in element.dataMapping) {
          mainRow[targetKey] = item[element.dataMapping[targetKey]];
        }
    
        // Extra rows based on extraRows configuration
        const extraRows = (element.extraRows || []).flatMap((extra) => {
          // Handle multiple fields in a single row
          if (extra.fields && Array.isArray(extra.fields)) {
            const allHidden = extra.fields.every((fieldConfig) => {
              const value = item[fieldConfig.field];
              return fieldConfig.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
            });
    
            if (allHidden) {
              return []; // Skip entirely if all fields are hidden
            }
    
            const extraRow = {};
            let hasContent = false;
            columnKeys.forEach((col) => {
              const fieldConfig = extra.fields.find(f => f.column === col.key);
              if (fieldConfig) {
                const value = item[fieldConfig.field];
                const shouldHide = fieldConfig.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
                if (!shouldHide && value !== null && value !== undefined) {
                  extraRow[col.key] = fieldConfig.format ? formatValue(value, fieldConfig.format) : value;
                  hasContent = true;
                } else {
                  extraRow[col.key] = "";
                }
              } else {
                extraRow[col.key] = "";
              }
            });
    
            return hasContent ? [extraRow] : []; // Only include if there's actual content
          }
    
          // Handle single field (e.g., name2)
          const value = item[extra.field];
          const shouldHide = extra.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
          if (!shouldHide && value !== null && value !== undefined) {
            const extraRow = {};
            columnKeys.forEach((col) => {
              extraRow[col.key] = col.key === extra.column ? value : "";
              if (extra.format && col.key === extra.column) {
                extraRow[col.key] = formatValue(value, extra.format);
              }
            });
            return [extraRow];
          }
          return [];
        });
    
        return [mainRow, ...extraRows];
      }).filter(row => {
        // Final filter to remove any fully empty rows
        return Object.values(row).some(val => val !== "" && val !== null && val !== undefined);
      });
    }

    // Process barcode elements
    if (element.type === "barcode" && element.content) {
      element.content = replacePlaceholders(element.content, data);
    }

    // Process loop elements
    if (element.type === "loop" && element.data && typeof element.data === "string") {
      const loopDataPath = element.data.replace(/{{|}}/g, "");
      const loopData = getDeepValue(data, loopDataPath);
      if (Array.isArray(loopData)) {
        return loopData.map((loopItem) => {
          return element.elements.map((loopElement) => {
            const newElement = { ...loopElement };
            if (newElement.content && typeof newElement.content === "string") {
              newElement.content = replacePlaceholders(newElement.content, loopItem);
            }
            return newElement;
          });
        }).flat();
      }
    }

    // Process conditional elements
    if (element.type === "conditional") {
      const condition = element.condition.replace(/{{|}}/g, "").trim();
      try {
        const conditionResult = new Function(...Object.keys(data), `return ${condition};`)(...Object.values(data));
        if (conditionResult) {
          return element.elements.map((el) => processReceiptTemplate({ elements: [el] }, data).elements).flat();
        }
        return [];
      } catch (error) {
        console.error("Error evaluating condition:", condition, error);
        return [];
      }
    }

    return element;
  });

  return processedTemplate;
};
const replacePlaceholders = (text, data) => {
  return text.replace(/{{([^}]+)}}/g, (match, placeholder) => {
    // Handle currentDateTime
    if (placeholder === "currentDateTime") {
      return new Date().toLocaleString();
    }

    // Get the value from the data object
    let value = getDeepValue(data, placeholder);

    // Format numbers correctly
    if (typeof value === "number") {
      return value === 0 ? "0.00" : value.toFixed(2);
    }

    // Handle expressions like {{grossTotal + grossReturnTotal}}
    if (placeholder.includes("+") || placeholder.includes("-") || placeholder.includes("*") || placeholder.includes("/")) {
      try {
        const resolvedExpression = placeholder.replace(/\b\w+\b/g, (varName) => {
          const val = getDeepValue(data, varName);
          return typeof val === "number" ? val : 0;
        });
        const result = eval(resolvedExpression);
        return result === 0 ? "0.00" : result.toFixed(2);
      } catch (error) {
        console.error("Error evaluating expression:", placeholder, error);
        return "0.00";
      }
    }

    return value !== null && value !== undefined ? value : "";
  });
};
const getDeepValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : null), obj);
};

const formatValue = (value, format) => {
  if (format === "currency" && typeof value === "number") {
    return value.toFixed(2);
  }
  return value;
};

export default CWHCPrint;