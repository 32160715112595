import axios from "axios";
import { URLs } from "./configuration";
import { getOAuthHeaders } from "../../constants/oAuthValidation";

axios.defaults.baseURL = process.env.REACT_APP_serverUrl;

const onRequestSuccess = (config) => {
  const authHeaders = getOAuthHeaders();
  config.headers.Authorization = `${authHeaders.access_token}`;
  return config;
};

const onRequestFail = (error) => {
  console.debug("request error", error);
  return Promise.reject(error);
};

axios.interceptors.request.use(onRequestSuccess, onRequestFail);

export default class API {
  static upsertPOSLog = (param) => {
    return axios.post("", param);
  };
  static upsertTill = (param) => {
    return axios.post("", param);
  };
  static upsertPOSActivity = (param) => {
    return axios.post("", param);
  };
}
