import { combineReducers } from "redux";

const defaults = {
  isFetching: false,
  error: null,
  data: null,
};

const getReducer = (actionName) => {
  const resetType = `${actionName.toUpperCase()}_RESET`;
  const requestType = `${actionName.toUpperCase()}_REQUEST`;
  const successType = `${actionName.toUpperCase()}_SUCCESS`;
  const failedType = `${actionName.toUpperCase()}_FAILURE`;
  const setType = `${actionName.toUpperCase()}_SET`;
  return function (state = { ...defaults }, action) {
    switch (action.type) {
      case resetType:
        return { ...state, ...defaults };
      case requestType:
        return { ...state, ...defaults, isFetching: true };
      case successType:
        return { ...state, ...defaults, data: { ...action.payload } };
      case failedType:
        console.log({ ...state, ...defaults, data: { ...action.payload } });
        debugger;
        if (action.error.response) {
          return { ...state, ...defaults, error: action.error.response.data };
        } else {
          return { ...state, ...defaults, error: action.error };
        }
      case setType:
        return { ...state, ...defaults, data: { ...state.data, ...action.payload } };
      default:
        return state;
    }
  };
};

const getOrderData = (state = {}, action) => {
  switch (action.type) {
    case "ORDERS_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const handleLock = (state = {}, action) => {
  switch (action.type) {
    case "ENABLE_LOCK":
      return { ...state, isScreenLocked: true };
    case "DISABLE_LOCK":
      return { ...state, isScreenLocked: false };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  upsert_poslog: getReducer("UPSERT_POSLOG"),
  upsert_till: getReducer("UPSERT_TILL"),
  upsert_pos_activity: getReducer("UPSERT_POS_ACTIVITY"),
  orders_data: getOrderData,
  screen_lock: handleLock,
});

export default rootReducer;
