import React from "react";
import Axios from "axios";

function salesReportCWHCPrinter(printData) {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let salesReportTemplate =tillData.tillAccess.cwrTill.salesReportTemplate.xmlcode2 ? JSON.parse(tillData.tillAccess.cwrTill.salesReportTemplate.xmlcode2) : {};

  const data = {
    tillData: tillData,
    items: printData[0].items || [], 
    username: localStorage.getItem("username"),
    date:printData[0]?.date?.split(' ')[0] || "",
    time:printData[0]?.date?.split(' ')[1] || "",
    totalAED:(printData[0].grossTotal + printData[0].grossReturnTotal)?.toFixed(2)
  };

  const processedTemplate = processReceiptTemplate(salesReportTemplate, data);

  // console.log("salesReportPrinter Input:", printData);
  // console.log("Processed Template:", JSON.stringify(processedTemplate, null, 2));
  let JSONData = JSON.stringify(processedTemplate, null, 2);
  Axios.post(`${printerURL}printer`, JSONData, {
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then(() => {
      console.log("Print success!");
    })
    .catch((response) => {
      console.log("Print failed!", response);
    });
  return processedTemplate; 
}

const processReceiptTemplate = (template, data) => {
  const processedTemplate = JSON.parse(JSON.stringify(template));

  processedTemplate.elements = processedTemplate.elements.flatMap((element) => {
    if (element.type === "text" && element.content && typeof element.content === "string") {
      element.content = replacePlaceholders(element.content, data);
    }

    if (element.type === "table" && element.data && typeof element.data === "string" && element.dataMapping) {
      const dataPath = element.data.replace(/{{|}}/g, "");
      const tableData = getDeepValue(data, dataPath) || [];

      const columnKeys = element.columns.map((col) => ({
        key: Object.keys(element.dataMapping).find((k) => element.dataMapping[k] === Object.values(element.dataMapping)[element.columns.indexOf(col)]),
        width: col.width,
        alignment: col.alignment,
        format: col.format
      }));

      element.data = tableData.flatMap((item) => {
        const mainRow = {};
        for (const targetKey in element.dataMapping) {
          mainRow[targetKey] = item[element.dataMapping[targetKey]];
        }

        const extraRows = (element.extraRows || []).flatMap((extra) => {
          if (extra.fields && Array.isArray(extra.fields)) {
            const allHidden = extra.fields.every((fieldConfig) => {
              const value = item[fieldConfig.field];
              return fieldConfig.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
            });
            if (allHidden) return [];

            const extraRow = {};
            let hasContent = false;
            columnKeys.forEach((col) => {
              const fieldConfig = extra.fields.find(f => f.column === col.key);
              if (fieldConfig) {
                const value = item[fieldConfig.field];
                const shouldHide = fieldConfig.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
                if (!shouldHide && value !== null && value !== undefined) {
                  extraRow[col.key] = fieldConfig.format ? formatValue(value, fieldConfig.format) : value;
                  hasContent = true;
                } else {
                  extraRow[col.key] = "";
                }
              } else {
                extraRow[col.key] = "";
              }
            });
            return hasContent ? [extraRow] : [];
          }

          const value = item[extra.field];
          const shouldHide = extra.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
          if (!shouldHide && value !== null && value !== undefined) {
            const extraRow = {};
            columnKeys.forEach((col) => {
              extraRow[col.key] = col.key === extra.column ? value : "";
              if (extra.format && col.key === extra.column) {
                extraRow[col.key] = formatValue(value, extra.format) ;
              }
            });
            return [extraRow];
          }
          return [];
        });

        return [mainRow, ...extraRows];
      }).filter(row => Object.values(row).some(val => val !== "" && val !== null && val !== undefined));
    }

    return element;
  });

  return processedTemplate;
};

const replacePlaceholders = (text, data) => {
  return text.replace(/{{([^}]+)}}/g, (match, placeholder) => {
    if (placeholder === "currentDateTime") return new Date().toLocaleString();

    // Handle expressions like {{printData.date.split(' ')[0]}}
    if (placeholder.includes(".")) {
      const value = getDeepValue(data, placeholder.split(".")[0]);
      if (value && placeholder.includes("split")) {
        try {
          const [base, method, args] = placeholder.match(/([^.]+)\.([^()]+)\(([^)]*)\)/);
          if (method === "split" && typeof value === "string") {
            return value.split(args.replace(/['"]/g, ""))[0];
          }
        } catch (e) {
          console.error("Error processing expression:", placeholder, e);
          return "";
        }
      }
    }

    // Handle arithmetic expressions like {{printData.grossTotal + printData.grossReturnTotal}}
    if (placeholder.includes("+")) {
      try {
        const resolvedExpression = placeholder.replace(/\b\w+\.\w+\b/g, (varName) => {
          const val = getDeepValue(data, varName);
          return typeof val === "number" ? val : 0;
        });
        const result = eval(resolvedExpression);
        return typeof result === "number" ? result.toString() : "";
      } catch (error) {
        console.error("Error evaluating expression:", placeholder, error);
        return "";
      }
    }

    const value = getDeepValue(data, placeholder);
    return value !== null && value !== undefined ? (typeof value === "number" ? value.toString() : value) : "";
  });
};

// Deep value retrieval
const getDeepValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : null), obj);
};

// Value formatting
const formatValue = (value, format) => {
  if (format === "currency" && typeof value === "number") return value.toFixed(2);
  return value;
};

export default salesReportCWHCPrinter;