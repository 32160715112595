import db from "../../database";
import Axios from "axios";

const GiftCardCWHCPrint = async (printData, type) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
 let giftCardTemplate
  let giftCardData = await db.giftCardData?.toArray();

  giftCardData?.map((GCData) => {
    if (GCData?.type === "DIG") {
      giftCardTemplate = GCData?.printTemplate?.xmlcode2 ? JSON.parse(GCData?.printTemplate?.xmlcode2) :{};
    }
  })
  // Prepare the data object with all necessary sources
  const data = {
    tillData: tillData,
    printData: printData || {}, // Assuming printData is a single object
    type: type // Pass type for conditional logic
  };

  // Process the JSON template with dynamic data
  const processedTemplate = processReceiptTemplate(giftCardTemplate, data);

  // console.log("GiftCardHCPrint Input:", { printData, type });
  // console.log("Processed Template:", JSON.stringify(processedTemplate, null, 2));
  let GiftCardHCPrintPayload = JSON.stringify(processedTemplate, null, 2);
  Axios.post(`${printerURL}printer`, GiftCardHCPrintPayload, {
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then(() => {
      console.log("Gift Card Print success!");
    })
    .catch((response) => {
      console.log("Gift Card Print failed!", response);
    });
  return processedTemplate; // Return the processed JSON
};

// JSON processing function
const processReceiptTemplate = (template, data) => {
  const processedTemplate = JSON.parse(JSON.stringify(template));

  processedTemplate.elements = processedTemplate.elements.flatMap((element) => {
    if (element.type === "text" && element.content && typeof element.content === "string") {
      element.content = replacePlaceholders(element.content, data);
    }
    return element;
  });

  return processedTemplate;
};

// Placeholder replacement with expression evaluation
const replacePlaceholders = (text, data) => {
  return text.replace(/{{([^}]+)}}/g, (match, placeholder) => {
    if (placeholder === "currentDateTime") return new Date().toLocaleString();

    // Handle conditional expressions like {{type === 'Credit Note' ? 'Credit Note' : printData.name}}
    if (placeholder.includes("?")) {
      try {
        const [condition, trueVal, falseVal] = placeholder.split(/ *\? *| *: */);
        const conditionResult = evalCondition(condition, data);
        return conditionResult ? trueVal.trim() : falseVal.trim();
      } catch (error) {
        console.error("Error evaluating conditional expression:", placeholder, error);
        return "";
      }
    }

    // Handle nested paths like {{printData.cardNo}}
    const value = getDeepValue(data, placeholder);
    return value !== null && value !== undefined ? (typeof value === "number" ? value.toString() : value) : "";
  });
};

// Evaluate conditions for ternary expressions
const evalCondition = (condition, data) => {
  try {
    const [left, operator, right] = condition.split(/ *(===|!==|>|<|>=|<=) */);
    const leftVal = getDeepValue(data, left.trim()) ?? left.trim().replace(/['"]/g, "");
    const rightVal = getDeepValue(data, right.trim()) ?? right.trim().replace(/['"]/g, "");
    
    switch (operator) {
      case "===": return leftVal === rightVal;
      case "!==": return leftVal !== rightVal;
      case ">": return Number(leftVal) > Number(rightVal);
      case "<": return Number(leftVal) < Number(rightVal);
      case ">=": return Number(leftVal) >= Number(rightVal);
      case "<=": return Number(leftVal) <= Number(rightVal);
      default: return false;
    }
  } catch (error) {
    console.error("Error evaluating condition:", condition, error);
    return false;
  }
};

// Deep value retrieval
const getDeepValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : null), obj);
};

// Value formatting
const formatValue = (value, format) => {
  if (format === "currency" && typeof value === "number") return value.toFixed(2);
  return value;
};

export default GiftCardCWHCPrint;